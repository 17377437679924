<template>
  <div class="municipality-detail p-3">
    <div v-if="userLogged">
      <div class="row g-3 border-bottom mb-2 py-1 align-items-center">
        <div class="col-5">
          <div class="form-check form-switch">
            <input
                class="form-check-input"
                type="radio"
                role="switch"
                :value="layerComuniName"
                v-model="localSelectableLayer"
            />
            <label class="form-check-label">Seleziona per comune</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check form-switch">
            <input
                class="form-check-input"
                type="radio"
                role="switch"
                :value="layerAreeName"
                v-model="localSelectableLayer"
            />
            <label class="form-check-label">Selezione per area</label>
          </div>
        </div>
        <div class="col-3 text-center">
          <button
              type="button"
              class="btn btn-warning"
              title="Tasto per l'invio del bollettino all'applicazione mobile"
              @click.prevent.stop="publishNow"
          >Pubblica ora
          </button>
        </div>
      </div>
    </div>
    <p class="mb-0"><strong>{{ cardTitle }} </strong></p>
    <div class="row gx-1 align-items-end">
      <div class="col-md-10">
        <label class="form-label mb-1 mt-2">Aggiungi comune:</label>
        <Autocomplete
            ref="autocomplete"
            @input="getItems"
            @onSelect="onSelect"
            :results="items"
            :debounce="1"
            :display-item="displayItem"
            placeholder="Digitare il comune che si vuole aggiungere"
            class="form-control"
            :class="{'is-invalid':isInvalid}"
        />

      </div>
      <div class="col-md-2">
        <button
            :disabled="!selectedItem"
            type="submit"
            class="btn btn-primary px-2"
            style="max-width: 72px;
              width: 72px;
              text-align: center;"
            @click.stop.prevent="addItem"
        >Aggiungi
        </button>
      </div>
      <div class="col-12">
        <div :class="{'is-invalid':isInvalid}" class="invalid-feedback">
          Comune già presente in lista
        </div>
      </div>
    </div>
    <div v-if="selectedItemsOnMap.length !== 0" class="row mt-2">
      <div class="col-8 pt-2">
        <p class="mb-0" v-if="false">
          <strong>
            Giornata in mappa:
            <i>{{ currentFormattedDay }}</i>
          </strong>
        </p>

        <p class="mb-0">
          <strong>
            Comuni selezionati:
            <i>{{ selectedItemsOnMap.length }}</i>
          </strong>
        </p>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-link btn-link_danger float-end" @click="removeList">
          <span class="text-danger"> Svuota selezione </span>
        </button>
      </div>
      <div class="col-12">
        <div class="card" style="background: #ffffff">
          <div class="list-group-wrapper" :class="{'is-logged':userLogged}">
            <div class="list-group list-group-results p-2" v-for="(i, index) in selectedItemsOnMap" :key="index">
              <div class="row">
                <div class="col">
                  <h6><strong> {{ i.comune }}</strong></h6>
                </div>
                <div class="col align-items-end text-end">
                  <button type="button" class="btn btn-danger btn-close" @click="removeItem(i)"></button>
                </div>
              </div>
              <div class="row p-2">
                <div class="col" :class="{'is-logged':userLogged}">
                  <div class="row">
                    <div class="col list-group-days_label">
                      <i>Giorni di prescrizione</i>
                    </div>
                    <div class="col-12 days-wrapper border">
                      <div class="list-group-days limitation-days"
                           v-for="(l, index) in i.p_days" :key="index"
                           :class="[{ 'isToday': l.day === today}, getStatus(l)]">
                        {{ l.day }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col list-group-days_label">
                      <i>Giornate indicative di tendenza</i>
                    </div>
                    <div class="col-12 days-wrapper days-wrapper-weekly-trend border">
                      <div class="list-group-days weekly-trend"
                           v-for="(t, index) in i.t_days" :key="index"
                           :class="[{ 'isToday': t.day === today}, getStatus(t)]">
                        {{ t.day }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <p class="mb-0 mt-0">
                <strong>
                  {{ refreshDaysCount(i) }}
                </strong>
                <em>
                  Giornate di DIVIETO da inizio Servizio
                </em>
              </p>
            </div>
          </div>
          <div class="py-2 border-top border-3" v-if="userLogged">
            <button
                type="button"
                class="btn btn-primary mx-2"
                @click.stop.prevent="noProhibition()"
            > Cambia in non divieto
            </button>
            <button
                type="button"
                class="btn btn-danger"
                @click.stop.prevent="prohibition()"
            >Cambia in divieto
            </button>
            <button
                v-if="false"
                type="button"
                class="btn btn-outline-info mx-2"
                @click.prevent.stop="resetCurrentDate(false)"
            >Ripristina data corrente
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2" v-if="userLogged">
        <button
            type="button"
            class="btn btn-outline-info mx-2"
            @click.prevent.stop="resetCurrentDate(true)"
        >Ripristina selezione
        </button>
        <button
            type="button"
            class="btn btn-info"
            @click.prevent.stop="save"
        >Salva selezione
        </button>
      </div>
    </div>
  </div>
  <toasts-messages
      toast-position="top"
      @close="closeToast"
      :toast-style="toastStyle"
      :class="{'show':toastShow }"
      :message="toastMessage"
  />
</template>

<script>
import Autocomplete from 'vue3-autocomplete';
import {mapGetters} from "vuex";
import {DATE_FORMATS, LAYER_NAMES, STYLING_STATUS} from "@/lib/constants";
import {canAddItem} from "@/lib/utils";
import ToastsMessages from "@/components/ToastsMessages";
import AppService from "@/services/app.service";

export default {
  name: "MunicipalityDetail",
  components: {
    ToastsMessages,
    Autocomplete
  },
  computed: {
    ...mapGetters({
      userLogged: "auth/userLogged",
      currentSeason: "currentSeason",
      currentBulletin: "currentBulletin",
      currentWeek: "currentWeek",
      currentDay: "currentDay",
      selectedItemsOnMap: "selectedItemsOnMap",
      currentSelectableLayer: "currentSelectableLayer",
    }),
    currentFormattedDay() {
      return this.currentDay.format(DATE_FORMATS.DATE_FORMAT_DD_MMMM_YYYY);
    },
    today() {
      return this.currentDay.format(DATE_FORMATS.DATE_FORMAT_DD_MMM);
    },
    layerAreeName() {
      return LAYER_NAMES.AREE;
    },
    layerComuniName() {
      return LAYER_NAMES.COMUNI;
    }
  },
  data() {
    return {
      cardTitle: 'Dettaglio comuni',
      items: [],
      selectedItem: null,
      isInvalid: false,
      localSelectableLayer: null,
      toastShow: false,
      toastStyle: 'success',
      toastMessage: ''
    }
  },
  methods: {
    getStatus(item) {
      return item.status === true ? STYLING_STATUS.STATUS_OK : (item.status === false ? STYLING_STATUS.STATUS_KO : STYLING_STATUS.STATUS_NULL);
    },
    async getItems(q) {
      this.isInvalid = false;
      this.items = (q && q.length > 2) ? await AppService.searchForComuni(q) : []
    },
    onSelect(item) {
      const ac = this.$refs.autocomplete;
      if (ac) {
        ac.setText(item.comune);
        document.activeElement.blur();
        this.selectedItem = item;
      }
    },
    displayItem(item) {
      return item.comune;
    },
    clearText() {
      this.$refs.autocomplete.setText();
    },
    async addItem() {
      const pro_com = this.selectedItem.pro_com;
      if (canAddItem(this.selectedItemsOnMap, pro_com)) {
        this.$store.dispatch("addToSelectionOnMap", {pro_com: pro_com});
        this.selectedItem = null;
        this.items = [];
        this.clearText();
      } else {
        this.isInvalid = true;
      }
    },
    refreshDaysCount(item, printDetail = false) {
      const _day = this.currentDay;
      const _start = this.currentSeason.seasonFirstDate;
      let n = item.p_days_count;

      item.p_days.forEach(d => {
        if (d.day_as_date >= _start && d.day_as_date <= _day) {
          n += d.status === false ? 1 : 0;
        }
      })
      item.t_days.forEach(d => {
        if (d.day_as_date >= _start && d.day_as_date <= _day) {
          n += d.status === false ? 1 : 0;
        }
      })
      if (printDetail) {
        this.currentSeason.printWarning(_day, n, item.comune);
      }
      return n;
    },
    removeList() {
      this.$store.dispatch("resetSelectionOnMap");
      this.isInvalid = false;
    },
    removeItem(item) {
      this.$store.dispatch("removeFromSelectionOnMap", [item.pro_com]);
    },
    isEditingForbidden() {
      const b = this.currentSeason.isEditingSlot(this.currentDay);
      if (!b) {
        this.showHideToast("Editing non consentito in questo momento", "warning");
      }
      return !b;
    },
    setDayStatus(status_value, restore) {
      if (this.isEditingForbidden()) {
        return false
      }
      const cDay = this.currentDay.format(DATE_FORMATS.DATE_FORMAT_DD_MMM);
      this.selectedItemsOnMap.forEach(item => {
        item.p_days.filter(dt => dt.day === cDay).forEach(dt => {
          if (restore === true) {
            if (dt.status_backup !== undefined && dt.status !== dt.status_backup) {
              dt.status = dt.status_backup;
            }
          } else {
            if (dt.status_backup === undefined) {
              dt.status_backup = dt.status;
            }
            if (dt.status !== status_value) {
              dt.status = status_value;
            }
          }
        })
      })
    },
    noProhibition() {
      this.setDayStatus(true, false);
    },
    prohibition() {
      this.setDayStatus(false, false);
    },
    async resetCurrentDate(serverSide = false) {
      if (this.isEditingForbidden()) {
        return false;
      }
      if (serverSide) {
        const payload = {
          bulletin: this.currentBulletin,
          iso_date: this.currentDay.format(DATE_FORMATS.DATE_FORMAT_ISO),
          istat_list: this.selectedItemsOnMap.map(item => item.pro_com)
        }
        try {
          const response = await AppService.resetComuniInfo(payload);
          this.showHideToast(response.message);
          this.$store.dispatch("reloadData");
        } catch (ex) {
          this.showHideToast(ex, 'error');
        }
      } else {
        this.setDayStatus(null, true);
        this.showHideToast('Data corrente ripristinata');
      }
    },
    async save() {
      if (this.isEditingForbidden()) {
        return false;
      }
      const payload = {
        bulletin: this.currentBulletin,
        iso_date: this.currentDay.format(DATE_FORMATS.DATE_FORMAT_ISO),
        istat_list: []
      }
      const cDay = this.currentDay.format(DATE_FORMATS.DATE_FORMAT_DD_MMM);
      this.selectedItemsOnMap.forEach(item => {
        item.p_days.filter(dt => dt.day === cDay).forEach(dt => {
          payload.istat_list.push([item.pro_com, dt.status]);
        })
      })
      try {
        const response = await AppService.saveComuniInfo(payload);
        this.showHideToast(response.message);
        this.$store.dispatch("reloadData");
      } catch (ex) {
        this.showHideToast(ex, 'error');
      }
    },
    async publishNow() {
      if (this.isEditingForbidden()) {
        return false;
      }
      try {
        const response = await AppService.publishBulletin();
        this.showHideToast(response.message);
      } catch (e) {
        this.showHideToast(e.message, "warning");
      }
    },
    closeToast() {
      this.toastShow = false
    },
    showHideToast(message, style = 'success', timeout = 2000) {
      this.toastStyle = style;
      this.toastMessage = message;
      this.toastShow = true;
      setTimeout(() => this.toastShow = false, timeout);
    }
  },
  mounted() {
    this.localSelectableLayer = this.currentSelectableLayer;
  },
  watch: {
    currentWeek: function () {
      this.$store.dispatch("resetSelectionOnMap");
      this.selectedItem = null;
      this.items = [];
      this.clearText();
    },
    localSelectableLayer: function (value) {
      this.$store.dispatch("setCurrentSelectableLayer", value);
    },
    currentSelectableLayer: function (value) {
      this.localSelectableLayer = value;
    }
  }
}
</script>
