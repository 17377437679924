<template>
  <!-- card -->
  <div class="card">
    <!-- card-header -->
    <div class="card-header">
      <i>{{ titleP }}</i>
    </div>
    <!-- card-body -->
    <div class="list-group list-group-flush">
      <div v-for="(item, index) in daysP"
           :key="index"
           class="list-group-item"
      >
        <div class="form-check">
          <input
              :disabled="!isEnabled(item)"
              class="form-check-input"
              type="radio"
              v-model="selected"
              :value="index"
              @click="onClick(index)">
          <label class="form-check-label">
            {{ item }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <!-- card -->
  <div class="card">
    <!-- card-header -->
    <div class="card-header">
      <i>{{ titleT }}</i>
    </div>
    <!-- card-body -->
    <div class="list-group list-group-flush">
      <div v-for="(item, index) in daysT"
           :key="index + daysP.length"
           class="list-group-item"
      >
        <div class="form-check">
          <input
              :disabled="!isEnabled(item)"
              class="form-check-input"
              type="radio"
              v-model="selected"
              :value="index + daysP.length"
              @click="onClick(index + daysP.length)">
          <label class="form-check-label">
            {{ item }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {DATE_FORMATS, MAP_NAMES} from "@/lib/constants";

export default {
  name: "WeekDaysSelectorCard",
  emits: ["selected"],
  props: {
    titleP: {
      type: String,
      required: false,
      default() {
        return "Giorni di prescrizione"
      }
    },
    titleT: {
      type: String,
      required: false,
      default() {
        return "Giornate indicative di tendenza"
      }
    },
    daysP: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    daysT: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters({
      currentSeason: "currentSeason",
      currentMap: "currentMap",
      userLogged: "auth/userLogged"
    })
  },
  data() {
    return {
      selected: 0
    }
  },
  methods: {
    onClick(index) {
      this.$emit("selected", index);
    },
    isEnabled(item) {
      const dt = moment(item, DATE_FORMATS.L);
      return (this.userLogged && this.currentMap !== MAP_NAMES.MAP_1.label) || this.currentSeason.dayIsValid(dt);
    },
    getFirstIndex() {
      return this.daysP.findIndex(item => this.isEnabled(item));
    }
  },
  watch: {
    daysP: function () {
      this.selected = this.getFirstIndex();
      this.onClick(this.selected);
    }
  }
}
</script>

<style scoped>

</style>
