const API_URLS = Object.freeze({
    /* vector */
    AREE: "/api/aree/",
    COMUNI: "/api/comuni/",
    PROVINCEGEO: "/api/provincegeo/",
    ZONE: "/api/zone/",
    /* auth */
    AUTH_LOGIN: "/auth/login/",
    AUTH_LOGOUT: "/auth/logout/",
    TOKEN_REFRESH: "/auth/refresh/",
    /* application */
    BULLETINS: "/bulletins/",
    BULLETIN_PUBLISH: "/bulletins/publish/",
    COMUNI_INFO: "/comuni_info/",
    COMUNI_INFO_RESET: "/comuni_info/reset/",
    COMUNI_INFO_SAVE: "/comuni_info/save/",
    COMUNI_SEARCH: "/comuni_search/",
    ISTAT_CONCESSIONI: "/concessioni/",
    ISTAT_MODELLISTICA: "/modellistica/",
    ISTAT_INFO: "/istatbydate/",
    SEASON: "/season/",
    WEEK_INFO: "/weekbydate/",
    PM10: "/pm10/",
    PROVINCE: "/province/",
    BULLETINS_EXPORT:"/bulletins/export/"
})


const LAYER_NAMES = Object.freeze({
    AREE: "Aree",
    COMUNI: "Comuni",
    PROVINCE: "Province",
    ZONE: "ZonePedoclimatiche",
    PM10: "PM10"
})

const FIELD_NAMES = Object.freeze({
    COD_ISTAT: "PRO_COM",
    STYLE_NAME: "STYLE_NAME"
})

const MAP_NAMES = Object.freeze({
    MAP_1: {label: 'Praticabilità', for_admin: false, is_valid: true},
    MAP_2: {label: 'Conteggio Chiusure', for_admin: true, is_valid: true},
    MAP_3: {label: 'Rischio Violazione Chiusure', for_admin: true, is_valid: false},
    MAP_4: {label: 'Modellistica', for_admin: true, is_valid: true},
    MAP_5: {label: 'Limitazioni agricoltura', for_admin: false, is_valid: true}
})

const STYLING_STATUS = Object.freeze({
    STATUS_NULL: '',
    STATUS_OK: 'practicable',
    STATUS_KO: 'not-practicable'
})

const DATE_FORMATS = Object.freeze({
    DATE_FORMAT_ISO: "YYYY-MM-DD",
    DATE_FORMAT_ISO_HH_MM: "YYYY-MM-DD hh:mm",
    DATE_FORMAT_IT: "DD/MM/YYYY",
    DATE_FORMAT_DD_MMM: "DD MMM",
    DATE_FORMAT_DD_MMMM_YYYY: "DD MMMM YYYY",
    L: "L"
})

const EVENT_NAMES = Object.freeze({
    SELECTED_LAYER_CHANGED: "selectedLayerChanged"
})

export {
    API_URLS,
    LAYER_NAMES,
    FIELD_NAMES,
    DATE_FORMATS,
    EVENT_NAMES,
    MAP_NAMES,
    STYLING_STATUS
}
