import axiosInstance from "./api";
import TokenService from "./token.service";
import {API_URLS} from "@/lib/constants";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== API_URLS.AUTH_LOGIN && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    console.warn("token expired");

                    try {
                        const result = await axiosInstance.post(API_URLS.TOKEN_REFRESH, {
                            refresh: TokenService.getLocalRefreshToken(),
                        });

                        const accessToken = result.data.access;
                        console.debug(accessToken)
                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);

                        originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;

                        return axiosInstance.request(originalConfig);
                    } catch (_error) {
                        TokenService.removeUser();
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;